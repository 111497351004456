/* -----------------------------------------
   Gallery pictures (keisti cia norint didinti tarpus tarp nuotrauku!!!)
----------------------------------------- */

.block-grid.four-up {
    margin: 0 -6px;
}
.block-grid.four-up > li {
    padding: 0px 6px 12px 6px;
}
.block-grid.four-up.partners li {
    padding-bottom: 15px;
}

.block-grid {
    .thumbnail {
        margin-bottom: 0px;

        &:hover,
        &:focus,{
            border-color: #8cc14b;
        }
    }
}
