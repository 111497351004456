/* -----------------------------------------
            Banners
----------------------------------------- */
.banners_text {
    text-align: center;
    padding: 20px 0;
    font-size: 36px;
}
#banners_wrapper {
    padding-top: 20px;
}
.banners li {
    position: relative;
    margin: 20px 0;
}
.banners li a.on_flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.banner_title {
    text-transform: uppercase;
    font-size: 16px;
    color: #353535;
    &:hover {
        color: #353535;
    }
}

.banners a {
    color: #353535;
    &:hover {
        color: #353535;
    }
}

.banner_img_holder {
    border: #dcdcdc 1px solid;
    overflow: hidden;
    margin-bottom: 15px;
    img {
        width: 100%;
        height: auto;
        transition: all .3s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
}