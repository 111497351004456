/* -----------------------------------------
                Titles
----------------------------------------- */

.content_title {
    font-size: 16px;
    font-weight: 300;
    color: #1f1f1f;
    text-transform: uppercase;
}
.content_title a {
    color: #1f1f1f;
}
.content_title a:hover {
    text-decoration: underline;
    color: #1f1f1f;
}
.module_title {
    font-size: 30px;
    font-weight: 400;
    color: #1f1f1f;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}
.articles_title {
     color: #1f1f1f;
     font-size: 20px;
}
.footer_title {
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 10px 0;
}
.image_title {
    text-align: center;
}