/* -----------------------------------------
            Bottom Menu
----------------------------------------- */

#bottom-menu {
    color: #525252;
    
    li {
        list-style: none;
        margin: 8px 0;
    }
    
    a {
        color: #525252;
        line-height: 14px;
        font-size: 16px;
        
        &:hover {
            text-decoration: underline;
        }
    }
}