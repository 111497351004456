/* -----------------------------------------
            Slider
----------------------------------------- */
.slider-wrapper {
    position: relative;
}
.nivo-caption .caption_title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}
.nivo-caption .caption_description {
    line-height: 20px;
}

.theme-light a.nivo-prevNav {
    left: 10px;
    right: 0;
    bottom: 0;
    top: 30%;
    background: none;
    font-size: 30px;
}

.theme-light a.nivo-nextNav {
    left: initial;
    right: 10px;
    bottom: 0;
    top: 30%;
    background: none;
}
.theme-light a.nivo-prevNav:hover {
    background: none;
    opacity: 1;
}

.theme-light a.nivo-nextNav:hover {
    background: none;
    opacity: 1;
}
.theme-light .nivo-directionNav a {
    text-indent: 0;
    font-size: 80px;
    color: #fff;
}

@media only screen and (max-width: 991px) {
    .theme-light .nivo-directionNav a {
        display: none;
        font-size: 35px;
    }
    
}