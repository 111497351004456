/* -----------------------------------------
            Pagination
----------------------------------------- */

.pagination-centered {
    text-align: center;
}

ul.pagination li.current a,
ul.pagination li.current a:hover,
ul.pagination li a:hover {
    color: #fff;
    background-color: #8cc14b;
}