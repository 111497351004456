@media only screen and (max-width: 768px) {
    #logo img {
        width: 65%;
    }
    
    #logo {
        text-align: left!important;
    }
}
@media only screen and (max-width: 500px) {
    #logo img {
        width: 45%;
    }
}