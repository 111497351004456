/* -----------------------------------------
                FOOTER
----------------------------------------- */
.map {
    display: block;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    margin-top: 40px;
}

@media only screen and (max-width: 1400px) {
    .map{
        width: 100%;
    }
}

#copyright_wrapper {
    margin-top: 38px;
    
    .copyright-text {
        a {
            line-height: 20px;
            font-size: 10px;
            color: #fff;
        }
        a:hover {
            color: #f2702c;
        }

        p {
            margin: 0px;
            line-height: 20px;
            font-size: 14px;
            color: #fff;
        }
    }
}
@media only screen and (max-width: 768px) {
    #copyright_wrapper p {

                margin: 0px;
                line-height: 20px;
                font-size: 10px!important;
                color: #fff;
    }
}