/* -----------------------------------------
            Languages
----------------------------------------- */

#languages {
    position: absolute;
    right: 0;
    top: -20px;
    ul {
        margin: 0;
        padding: 0;
    }
    
    li {
        margin: 0;
        padding: 0;
    }
    
    a {
        padding: 5px 5px;
        color: #1c1c1c;
        text-transform: uppercase;
        
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: #fff;
            background: #f38132;
            text-decoration: none;
        }
    }
}
@media only screen and (min-width: 501px) and (max-width: 768px) {
    #languages {
        position: absolute;
        right: 100px;
        top: -56px;
    }
}
@media only screen and (max-width: 500px) {
    #languages {
        position: absolute;
        right: 100px;
        top: -38px;
    }
}