/* -----------------------------------------
            Articles
----------------------------------------- */

.news_image {
    margin: 5px 10px 10px 0; 
}
.news_summary {
    
}
.news_description {
    
}
.text_block {

}