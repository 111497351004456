@charset "UTF-8";
/* -----------------------------------------
                Helpers
----------------------------------------- */
/* -----------------------------------------
                Base
----------------------------------------- */
@import url("http://fonts.googleapis.com/css?family=BenchNine:400,700,300&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Oswald");
.thumbnail {
  border-radius: 0px; }
  .thumbnail:hover, .thumbnail:focus, .thumbnail:active {
    border-color: #f2702c !important; }

/* -----------------------------------------
                Import Fonts  
----------------------------------------- */
/* -----------------------------------------
                Layout
----------------------------------------- */
@media only screen and (max-width: 768px) {
  #logo img {
    width: 65%; }
  #logo {
    text-align: left !important; } }

@media only screen and (max-width: 500px) {
  #logo img {
    width: 45%; } }

/* -----------------------------------------
                Wrappers
----------------------------------------- */
#wrap {
  min-height: 100%;
  background: #e6e2df; }

#main {
  /*    max-width: 1240px;*/
  margin: 0 auto;
  background: #fff; }

#header_wrapper {
  margin: 20px auto; }

#content_wrapper {
  padding-bottom: 20px; }

#footer_wrapper {
  background: #353535;
  padding: 0 0 0 0;
  height: 100px;
  width: 100%; }

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 0px;
    padding-right: 0px; } }

@media (max-width: 768px) {
  #menu_wrapper {
    position: absolute;
    top: 5%;
    width: 100%; }
  #middle-menu {
    background-color: initial !important; }
  div#Navbar {
    background-color: #353535 !important;
    z-index: 13;
    position: relative; }
  #header_wrapper {
    z-index: 11;
    position: relative; }
  button.navbar-toggle {
    z-index: 12;
    position: relative;
    background-color: #353535; } }

/* -----------------------------------------
                Reset styles
----------------------------------------- */
/*body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
    font-size: 15px;
    font-weight: 300;
    font-family: "Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
    color: #272727;
} */
body {
  font-family: 'Oswald', sans-serif; }

ul, ol, li {
  margin: 0;
  padding: 0; }

li {
  list-style-position: inside; }

label {
  display: inline; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300; }

h2 {
  font-family: 'BenchNine', sans-serif;
  font-size: 20px;
  color: #003a69; }

h5 {
  font-size: 16px;
  color: #286eaa; }

h4 {
  font-size: 20px;
  color: #003a69; }

a {
  color: #f2702c;
  cursor: pointer; }
  a:hover, a:focus {
    color: #f38132; }

p {
  font-size: 12px;
  line-height: 24px;
  text-align: justify;
  color: #1f1f1f; }

.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.hidden {
  display: none; }

.white {
  background-color: #fff; }

.transparent {
  background-color: transparent; }

.pointer:hover {
  cursor: pointer; }

.form-control {
  border-radius: 0px; }

.form-control.error {
  border-color: red; }

.submitButton {
  background-color: #f2702c;
  color: #fff; }

.modal-title {
  color: #000; }

li.desktop_dont_show {
  display: none; }

@media only screen and (max-width: 768px) {
  li.desktop_dont_show {
    display: block; } }

/* -----------------------------------------
                FOOTER
----------------------------------------- */
.map {
  display: block;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  margin-top: 40px; }

@media only screen and (max-width: 1400px) {
  .map {
    width: 100%; } }

#copyright_wrapper {
  margin-top: 38px; }
  #copyright_wrapper .copyright-text a {
    line-height: 20px;
    font-size: 10px;
    color: #fff; }
  #copyright_wrapper .copyright-text a:hover {
    color: #f2702c; }
  #copyright_wrapper .copyright-text p {
    margin: 0px;
    line-height: 20px;
    font-size: 14px;
    color: #fff; }

@media only screen and (max-width: 768px) {
  #copyright_wrapper p {
    margin: 0px;
    line-height: 20px;
    font-size: 10px !important;
    color: #fff; } }

/* -----------------------------------------
                Components
----------------------------------------- */
/* -----------------------------------------
            Block Grid
----------------------------------------- */
.block-grid {
  display: block;
  overflow: hidden;
  padding: 0; }

.block-grid > li {
  display: block;
  float: left;
  height: auto; }

.block-grid.one-up {
  margin: 0; }

.block-grid.one-up > li {
  padding: 0 0 15px;
  width: 100%; }

.block-grid.two-up {
  margin: 0 -15px; }

.block-grid.two-up > li {
  padding: 0 15px 15px;
  width: 50%; }

.block-grid.three-up {
  margin: 0 -12px; }

.block-grid.three-up > li {
  padding: 0 12px 12px;
  width: 33.3333%; }

.block-grid.four-up {
  margin: 0 -10px; }

.block-grid.four-up > li {
  padding: 0 10px 10px;
  width: 25%; }

.block-grid.five-up {
  margin: 0 -8px; }

.block-grid.five-up > li {
  padding: 0 8px 8px;
  width: 20%; }

.block-grid.six-up {
  margin: 0 -8px; }

.block-grid.six-up > li {
  padding: 0 8px 8px;
  width: 16.6667%; }

.block-grid.seven-up {
  margin: 0 -8px; }

.block-grid.seven-up > li {
  padding: 0 8px 8px;
  width: 14.2857%; }

.block-grid.eight-up {
  margin: 0 -8px; }

.block-grid.eight-up > li {
  padding: 0 8px 8px;
  width: 12.5%; }

.block-grid.nine-up {
  margin: 0 -8px; }

.block-grid.nine-up > li {
  padding: 0 8px 8px;
  width: 11.1111%; }

.block-grid.ten-up {
  margin: 0 -8px; }

.block-grid.ten-up > li {
  padding: 0 8px 8px;
  width: 10%; }

.block-grid.eleven-up {
  margin: 0 -8px; }

.block-grid.eleven-up > li {
  padding: 0 8px 8px;
  width: 9.09091%; }

.block-grid.twelve-up {
  margin: 0 -8px; }

.block-grid.twelve-up > li {
  padding: 0 8px 8px;
  width: 8.33333%; }

@media only screen and (min-width: 1080px) {
  .block-grid.two-up > li:nth-child(2n+1),
  .block-grid.three-up > li:nth-child(3n+1),
  .block-grid.four-up > li:nth-child(4n+1),
  .block-grid.five-up > li:nth-child(5n+1),
  .block-grid.six-up > li:nth-child(6n+1),
  .block-grid.seven-up > li:nth-child(7n+1),
  .block-grid.eight-up > li:nth-child(8n+1),
  .block-grid.nine-up > li:nth-child(9n+1),
  .block-grid.ten-up > li:nth-child(10n+1),
  .block-grid.eleven-up > li:nth-child(11n+1),
  .block-grid.twelve-up > li:nth-child(12n+1) {
    clear: both; } }

@media only screen and (max-width: 1080px) and (min-width: 767px) {
  .block-grid > li {
    clear: none; }
  .block-grid.tab-one-up > li {
    width: 100%; }
  .block-grid.tab-two-up > li {
    width: 50%; }
  .block-grid.tab-three-up > li {
    width: 33.3333%; }
  .block-grid.tab-four-up > li {
    width: 25%; }
  .block-grid.tab-five-up > li {
    width: 20%; }
  .block-grid.tab-six-up > li {
    width: 16.6667%; }
  .block-grid.tab-seven-up > li {
    width: 14.2857%; }
  .block-grid.tab-eight-up > li {
    width: 12.5%; }
  .block-grid.tab-nine-up > li {
    width: 11.1111%; }
  .block-grid.tab-ten-up > li {
    width: 10%; }
  .block-grid.tab-eleven-up > li {
    width: 9.09091%; }
  .block-grid.tab-twelve-up > li {
    width: 8.33333%; }
  .block-grid.tab-two-up > li:nth-child(2n+1),
  .block-grid.tab-three-up > li:nth-child(3n+1),
  .block-grid.tab-four-up > li:nth-child(4n+1),
  .block-grid.tab-five-up > li:nth-child(5n+1),
  .block-grid.tab-six-up > li:nth-child(6n+1),
  .block-grid.tab-seven-up > li:nth-child(7n+1),
  .block-grid.tab-eight-up > li:nth-child(8n+1),
  .block-grid.tab-nine-up > li:nth-child(9n+1),
  .block-grid.tab-ten-up > li:nth-child(10n+1),
  .block-grid.tab-eleven-up > li:nth-child(11n+1),
  .block-grid.tab-twelve-up > li:nth-child(12n+1) {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .block-grid > li {
    clear: none; }
  .block-grid.mobile-one-up > li {
    width: 100%; }
  .block-grid.mobile-two-up > li {
    width: 50%; }
  .block-grid.mobile-three-up > li {
    width: 33.3333%; }
  .block-grid.mobile-four-up > li {
    width: 25%; }
  .block-grid.mobile-five-up > li {
    width: 20%; }
  .block-grid.mobile-six-up > li {
    width: 16.6667%; }
  .block-grid.mobile-seven-up > li {
    width: 14.2857%; }
  .block-grid.mobile-eight-up > li {
    width: 12.5%; }
  .block-grid.mobile-nine-up > li {
    width: 11.1111%; }
  .block-grid.mobile-ten-up > li {
    width: 10%; }
  .block-grid.mobile-eleven-up > li {
    width: 9.09091%; }
  .block-grid.mobile-twelve-up > li {
    width: 8.33333%; }
  .block-grid.mobile-two-up > li:nth-child(2n+1),
  .block-grid.mobile-three-up > li:nth-child(3n+1),
  .block-grid.mobile-four-up > li:nth-child(4n+1),
  .block-grid.mobile-five-up > li:nth-child(5n+1),
  .block-grid.mobile-six-up > li:nth-child(6n+1),
  .block-grid.mobile-seven-up > li:nth-child(7n+1),
  .block-grid.mobile-eight-up > li:nth-child(8n+1),
  .block-grid.mobile-nine-up > li:nth-child(9n+1),
  .block-grid.mobile-ten-up > li:nth-child(10n+1),
  .block-grid.mobile-eleven-up > li:nth-child(11n+1),
  .block-grid.mobile-twelve-up > li:nth-child(12n+1) {
    clear: both; } }

/* -----------------------------------------
            BreadCrumbs
----------------------------------------- */
.breadcrumb {
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .breadcrumb li.active a {
    color: #777;
    text-decoration: none;
    cursor: default; }

/* -----------------------------------------
            Buttons
----------------------------------------- */
.btn {
  border-radius: 0px;
  background-color: #f2702c;
  color: white;
  border-color: #353535; }
  .btn:hover, .btn:focus, .btn:active, .btn:target {
    background-color: #f27c2c !important;
    color: white !important;
    border-color: #353535 !important; }

.phone_number_mobile {
  display: none; }

@media only screen and (max-width: 768px) {
  .phone_number_mobile {
    display: block;
    text-align: center;
    color: #f2702c;
    font-size: 26px; }
  .phone_border {
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px;
    margin-top: 40px;
    border: 1px solid; } }

/* -----------------------------------------
                Titles
----------------------------------------- */
.content_title {
  font-size: 16px;
  font-weight: 300;
  color: #1f1f1f;
  text-transform: uppercase; }

.content_title a {
  color: #1f1f1f; }

.content_title a:hover {
  text-decoration: underline;
  color: #1f1f1f; }

.module_title {
  font-size: 30px;
  font-weight: 400;
  color: #1f1f1f;
  margin: 30px 0 10px 0;
  text-transform: uppercase; }

.articles_title {
  color: #1f1f1f;
  font-size: 20px; }

.footer_title {
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 10px 0; }

.image_title {
  text-align: center; }

/* -----------------------------------------
            Pagination
----------------------------------------- */
.pagination-centered {
  text-align: center; }

ul.pagination li.current a,
ul.pagination li.current a:hover,
ul.pagination li a:hover {
  color: #fff;
  background-color: #8cc14b; }

/* -----------------------------------------
                Menu Modules
----------------------------------------- */
/* -----------------------------------------
            Articles
----------------------------------------- */
.news_image {
  margin: 5px 10px 10px 0; }

/* -----------------------------------------
   Gallery pictures (keisti cia norint didinti tarpus tarp nuotrauku!!!)
----------------------------------------- */
.block-grid.four-up {
  margin: 0 -6px; }

.block-grid.four-up > li {
  padding: 0px 6px 12px 6px; }

.block-grid.four-up.partners li {
  padding-bottom: 15px; }

.block-grid .thumbnail {
  margin-bottom: 0px; }
  .block-grid .thumbnail:hover, .block-grid .thumbnail:focus {
    border-color: #8cc14b; }

/* -----------------------------------------
            Partners pictures
----------------------------------------- */
.cell {
  z-index: 10;
  border: 1px solid #ccc;
  padding: 5px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0 auto; }

.cell .outerContainer {
  display: table;
  overflow: hidden; }

.cell .outerContainer .innerContainer {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  text-align: center; }

.cell .outerContainer .innerContainer .element {
  line-height: 0 !important;
  font-size: 0; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-Jan-2017, 16:05:51
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.product_holder a {
  color: #f2702c;
  cursor: pointer; }
  .product_holder a:hover, .product_holder a:focus {
    color: #f38132;
    text-decoration: none; }

.product_holder .block-grid.four-up > li {
  padding: 0 14px 12px; }

.product_holder ul {
  list-style: none; }

.product_inside_image_holder img {
  width: 100%; }

@media only screen and (max-width: 991px) {
  .product_inside_image_holder {
    width: 100%; } }

.prods_gallery {
  border-radius: 0px; }
  .prods_gallery:hover {
    border-color: #f38132 !important; }

.product_category_img_holder {
  border: #dcdcdc 1px solid;
  overflow: hidden; }
  .product_category_img_holder img {
    width: 100%;
    height: auto;
    transition: all .3s ease-in-out; }
    .product_category_img_holder img:hover {
      transform: scale(1.1); }

.news_summary {
  font-family: arial,helvetica,sans-serif; }

.text_block {
  font-family: arial,helvetica,sans-serif; }

/* -----------------------------------------
                Main Modules
----------------------------------------- */
/* -----------------------------------------
            Top Menu
----------------------------------------- */
#top-menu {
  margin: 36px 10px; }
  #top-menu ul {
    margin: 0;
    padding: 0; }
  #top-menu li {
    margin: 0;
    padding: 0 10px;
    font-size: 16px;
    text-transform: uppercase; }
  #top-menu a {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #1f1f1f; }
    #top-menu a:hover, #top-menu a:focus, #top-menu a:active, #top-menu a.active {
      color: #f2702c;
      text-decoration: underline; }
  #top-menu .phone_number a {
    color: #f2702c; }
    #top-menu .phone_number a:hover {
      text-decoration: none; }
  #top-menu .write_us {
    background: #f38132;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#f59239, #f2702c);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#f59239, #f2702c);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#f59239, #f2702c);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#f59239, #f2702c);
    /* Standard syntax */
    padding: 10px 20px; }
    #top-menu .write_us a {
      margin-left: 0px;
      color: white; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #top-menu {
    margin-top: 90px;
    margin-bottom: 20px; } }

@media only screen and (max-width: 540px) {
  #top-menu {
    margin: 0; }
  #top-menu li {
    width: 49%;
    height: 40px; }
    #top-menu li a {
      font-size: 14px;
      display: block;
      text-align: center;
      vertical-align: middle; } }

@media only screen and (max-width: 342px) {
  #top-menu li {
    height: 20px;
    padding: 10px 0; }
  #top-menu .write_us {
    padding: 0; } }

@media only screen and (max-width: 768px) {
  #top-menu {
    display: none; } }

/* -----------------------------------------
            Middle Menu
----------------------------------------- */
#middle-menu {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #353535; }
  #middle-menu ul, #middle-menu li {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px; }
  #middle-menu .navbar-collapse {
    padding: 0; }
  #middle-menu .nav > li {
    border-right: 1px solid #616161;
    color: #fff; }
    #middle-menu .nav > li:last-child {
      border: none; }
    #middle-menu .nav > li a {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: lighter;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px; }
    #middle-menu .nav > li:hover, #middle-menu .nav > li:focus,
    #middle-menu .nav > li.active > a {
      color: #fff;
      background: #f38132; }
  #middle-menu li:hover, #middle-menu li:focus,
  #middle-menu li.active > a {
    color: #fff;
    background: #f38132; }

@media only screen and (min-width: 768px) {
  .container-fluid {
    padding: 0px; }
  .dropdown-submenu {
    position: relative; }
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px; }
  .dropdown-submenu:hover > .dropdown-menu {
    display: block; }
  .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px; }
  .dropdown-submenu:hover > a:after {
    border-left-color: #ffffff; }
  .dropdown-submenu .pull-left {
    float: none; }
  .dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px; }
  .root:hover > .dropdown-menu {
    display: block; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  #middle-menu .nav > li {
    height: 70px;
    display: table; }
  #middle-menu .nav > li.desktop_dont_show {
    height: 70px;
    display: none; }
  #middle-menu .nav > li > a {
    vertical-align: middle;
    display: table-cell; }
  #middle-menu .nav > li.active > a {
    height: 70px; } }

@media only screen and (max-width: 768px) {
  ul.nav.navbar-nav {
    margin-left: 0px;
    margin-right: 0px; } }

/* -----------------------------------------
            Bottom Menu
----------------------------------------- */
#bottom-menu {
  color: #525252; }
  #bottom-menu li {
    list-style: none;
    margin: 8px 0; }
  #bottom-menu a {
    color: #525252;
    line-height: 14px;
    font-size: 16px; }
    #bottom-menu a:hover {
      text-decoration: underline; }

/* -----------------------------------------
            Banners
----------------------------------------- */
.banners_text {
  text-align: center;
  padding: 20px 0;
  font-size: 36px; }

#banners_wrapper {
  padding-top: 20px; }

.banners li {
  position: relative;
  margin: 20px 0; }

.banners li a.on_flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }

.banner_title {
  text-transform: uppercase;
  font-size: 16px;
  color: #353535; }
  .banner_title:hover {
    color: #353535; }

.banners a {
  color: #353535; }
  .banners a:hover {
    color: #353535; }

.banner_img_holder {
  border: #dcdcdc 1px solid;
  overflow: hidden;
  margin-bottom: 15px; }
  .banner_img_holder img {
    width: 100%;
    height: auto;
    transition: all .3s ease-in-out; }
    .banner_img_holder img:hover {
      transform: scale(1.1); }

/* -----------------------------------------
                Carousel
----------------------------------------- */
#carousel_wrapper {
  padding: 0 0 40px 0; }

#carousel_wrapper .owl-carousel {
  padding: 0 30px; }

#carousel_wrapper .owl-carousel .item {
  padding: 10px 0px;
  margin: 10px;
  text-align: center;
  height: 90px; }

#carousel_wrapper .owl-carousel .item .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

#carousel_wrapper .owl-carousel .item img {
  vertical-align: middle;
  max-height: 90px; }

#carousel_wrapper .next,
#carousel_wrapper .prev {
  position: absolute;
  top: 55%;
  width: 32px;
  height: 32px;
  cursor: pointer; }

#carousel_wrapper .next {
  right: 0px;
  background: url("../img/next-horizontal.png") no-repeat 0 0; }

#carousel_wrapper .prev {
  left: 0px;
  background: url("../img/prev-horizontal.png") no-repeat 0 0; }

#carousel_wrapper .next:hover, #carousel_holder .next:focus,
#carousel_wrapper .prev:hover, #carousel_holder .prev:focus {
  background-position: -32px 0; }

#carousel_wrapper .next:active,
#carousel_wrapper .prev:active {
  background-position: -64px 0; }

/* -----------------------------------------
            Languages
----------------------------------------- */
#languages {
  position: absolute;
  right: 0;
  top: -20px; }
  #languages ul {
    margin: 0;
    padding: 0; }
  #languages li {
    margin: 0;
    padding: 0; }
  #languages a {
    padding: 5px 5px;
    color: #1c1c1c;
    text-transform: uppercase; }
    #languages a:hover, #languages a:focus, #languages a:active, #languages a.active {
      color: #fff;
      background: #f38132;
      text-decoration: none; }

@media only screen and (min-width: 501px) and (max-width: 768px) {
  #languages {
    position: absolute;
    right: 100px;
    top: -56px; } }

@media only screen and (max-width: 500px) {
  #languages {
    position: absolute;
    right: 100px;
    top: -38px; } }

/* -----------------------------------------
                Logo
----------------------------------------- */
#logo {
  margin: 15px 0 15px 0; }

@media only screen and (max-width: 768px) {
  #logo {
    text-align: center; } }

/* -----------------------------------------
            Mini Banners
----------------------------------------- */
#mini_banners_wrapper {
  background: #e6e2df;
  padding: 0 15px;
  margin: 0 -15px; }

#mini_banners_wrapper .min_banners li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 7px;
  margin-left: 0px;
  color: #4a4949; }

.min_banners li .min_banner_img {
  margin-right: 10px; }

.min_banners li .min_banner_title {
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px; }

.min_banners li .min_banner_text {
  font-weight: 300;
  font-size: 12px; }

.min_banners li a.on_flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }

/* -----------------------------------------
            Query Form
----------------------------------------- */
#query_form {
  margin: 0px; }

/* -----------------------------------------
            Slider
----------------------------------------- */
.slider-wrapper {
  position: relative; }

.nivo-caption .caption_title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700; }

.nivo-caption .caption_description {
  line-height: 20px; }

.theme-light a.nivo-prevNav {
  left: 10px;
  right: 0;
  bottom: 0;
  top: 30%;
  background: none;
  font-size: 30px; }

.theme-light a.nivo-nextNav {
  left: initial;
  right: 10px;
  bottom: 0;
  top: 30%;
  background: none; }

.theme-light a.nivo-prevNav:hover {
  background: none;
  opacity: 1; }

.theme-light a.nivo-nextNav:hover {
  background: none;
  opacity: 1; }

.theme-light .nivo-directionNav a {
  text-indent: 0;
  font-size: 80px;
  color: #fff; }

@media only screen and (max-width: 991px) {
  .theme-light .nivo-directionNav a {
    display: none;
    font-size: 35px; } }
