/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-Jan-2017, 16:05:51
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.product_holder {
    a {
        color: #f2702c;
        cursor: pointer;

        &:hover,
        &:focus {
            color: #f38132;
            text-decoration: none;
        }
    }
    
    .block-grid.four-up>li {
        padding: 0 14px 12px;
    }
}


.product_holder ul {
    list-style: none;
}

.product_inside_image_holder img {
    width: 100%;
}
@media only screen and (max-width: 991px) {
    .product_inside_image_holder {
        width: 100%;
    }
}

.prods_gallery {
    border-radius: 0px;
    
    &:hover {
        border-color: #f38132 !important;
    }
}

.product_category_img_holder {
    border: #dcdcdc 1px solid;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
        transition: all .3s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.news_summary {
    font-family: arial,helvetica,sans-serif;
}

.text_block {
    font-family: arial,helvetica,sans-serif;
}