/* -----------------------------------------
            BreadCrumbs
----------------------------------------- */

.breadcrumb {
    background: #f6f6f6;
    border: 1px solid #e9e9e9;
    
    @include border-radius(2px);
    
    li.active a {
        color: #777;
        text-decoration: none;
        cursor: default;
    }
}