/* -----------------------------------------
                Wrappers
----------------------------------------- */

#wrap {
    min-height: 100%;
    background: #e6e2df;
}
#main {
/*    max-width: 1240px;*/
    margin: 0 auto;
    background: #fff;
}
#header_wrapper {
    margin: 20px auto;
}
#menu_wrapper {
    
}
#slider_wrapper {
    
}
#content_wrapper {
    padding-bottom: 20px;
}
#query_wrapper {
    
}
#footer_wrapper {
    background: #353535;
    padding: 0 0 0 0;
    height: 100px;
    width: 100%;
} 

@media (min-width: 1200px){
    .container {
        width: 1200px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 768px){
    #menu_wrapper {
        position: absolute;
        top: 5%;
        width: 100%;
    }
    
    #middle-menu {
        background-color: initial !important;
    }
    
    
    div#Navbar {
        background-color: #353535!important;
        z-index: 13;
        position: relative;
    }
    
    #header_wrapper {
        z-index: 11;
        position: relative;
    }
    button.navbar-toggle {
        z-index: 12;
        position: relative;
        background-color: #353535;
    }
}

