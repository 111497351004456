/* -----------------------------------------
                Reset styles
----------------------------------------- */

/*body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
    font-size: 15px;
    font-weight: 300;
    font-family: "Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
    color: #272727;
} */

body {
    font-family: 'Oswald', sans-serif;
}
ul, ol, li {
    margin: 0;
    padding: 0;
}
li {
    list-style-position: inside;
}


label {
    display: inline;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}
h2 {
    font-family: 'BenchNine', sans-serif;
    font-size: 20px;
    color: #003a69;
}
h5 {
    font-size: 16px;
    color: #286eaa;
}
h4 {
    font-size: 20px;
    color: #003a69;
}


a {
    color: #f2702c;
    cursor: pointer;

    &:hover,
    &:focus {
        color: #f38132;
    }
}


p {
    font-size: 12px; 
    line-height: 24px; 
    text-align: justify;
    color: #1f1f1f;
}


.clear {
    clear: both;
}
.left {
    float: left;
}
.right {
    float: right;
}
.hidden {
    display: none;
}
.white {
    background-color: #fff;
}
.transparent {
    background-color: transparent;
}
.pointer:hover {
    cursor: pointer;
}

.form-control {
    border-radius: 0px;
}

.form-control.error {
    border-color: red;
}

.submitButton {
    background-color: #f2702c;
    color: #fff;
}
.modal-title {
    color: #000;
}

li.desktop_dont_show {
    display: none;
}
@media only screen and (max-width: 768px) {
    li.desktop_dont_show {
        display: block;
    }
}