/* -----------------------------------------
            Middle Menu
----------------------------------------- */

#middle-menu {
    @include border-radius(0px);
    margin: 0;
    padding: 0;
    border: none;
    background-color: #353535;
    
    ul, li {
        @include border-radius(0px);
    }
    
    .navbar-collapse {
        padding: 0;
    }
    
    .nav > li {

        border-right: 1px solid #616161;
        color: #fff;
        &:last-child {
            border: none;
        }
        a {
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: lighter;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
        }
        &:hover,
        &:focus,
        &.active > a {
            color: #fff;
            background: #f38132;
        }
    }    li {

        &:hover,
        &:focus,
        &.active > a {
            color: #fff;
            background: #f38132;
        }
    }

}
 
@media only screen and (min-width: 768px) {    
    .container-fluid {
        padding: 0px;
    }
    
    .dropdown-submenu {
        position: relative;
    } 
    
    .dropdown-submenu > .dropdown-menu {
        top:0;
        left:100%;
        margin-top:-6px;
        margin-left:-1px;
        -webkit-border-radius:0 6px 6px 6px;
        -moz-border-radius:0 6px 6px 6px;
        border-radius:0 6px 6px 6px;
    } 
    .dropdown-submenu:hover > .dropdown-menu{
        display:block;
    }

    .dropdown-submenu > a:after {
        display:block;
        content:" ";
        float:right;
        width:0;
        height:0;
        border-color:transparent;
        border-style:solid;
        border-width:5px 0 5px 5px;
        border-left-color:#cccccc;
        margin-top:5px;
        margin-right:-10px;
    }

    .dropdown-submenu:hover > a:after {
        border-left-color:#ffffff;
    }

    .dropdown-submenu .pull-left {
        float:none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        left:-100%;
        margin-left:10px;
        -webkit-border-radius:6px 0 6px 6px;
        -moz-border-radius:6px 0 6px 6px;
        border-radius:6px 0 6px 6px;
    }

    .root:hover > .dropdown-menu{
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
    #middle-menu .nav>li {
        height: 70px;
        display: table;
    }
    #middle-menu .nav>li.desktop_dont_show {
        height: 70px;
        display: none;
    }
    #middle-menu .nav>li>a{
        vertical-align: middle;
        display: table-cell;
    }
    #middle-menu .nav>li.active>a {
        height: 70px;
    }
        
}

@media only screen and (max-width: 768px) {   
    ul.nav.navbar-nav {
        margin-left: 0px;
        margin-right: 0px;
    }
}