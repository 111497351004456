/* -----------------------------------------
            Block Grid
----------------------------------------- */

.block-grid {
    display: block;
    overflow: hidden;
    padding: 0;
}
.block-grid > li {
    display: block;
    float: left;
    height: auto;
}
.block-grid.one-up {
    margin: 0;
}
.block-grid.one-up > li {
    padding: 0 0 15px;
    width: 100%;
}
.block-grid.two-up {
    margin: 0 -15px;
}
.block-grid.two-up > li {
    padding: 0 15px 15px;
    width: 50%;
}
.block-grid.three-up {
    margin: 0 -12px;
}
.block-grid.three-up > li {
    padding: 0 12px 12px;
    width: 33.3333%;
}
.block-grid.four-up {
    margin: 0 -10px;
}
.block-grid.four-up > li {
    padding: 0 10px 10px;
    width: 25%;
}
.block-grid.five-up {
    margin: 0 -8px;
}
.block-grid.five-up > li {
    padding: 0 8px 8px;
    width: 20%;
}
.block-grid.six-up {
    margin: 0 -8px;
}
.block-grid.six-up > li {
    padding: 0 8px 8px;
    width: 16.6667%;
}
.block-grid.seven-up {
    margin: 0 -8px;
}
.block-grid.seven-up > li {
    padding: 0 8px 8px;
    width: 14.2857%;
}
.block-grid.eight-up {
    margin: 0 -8px;
}
.block-grid.eight-up > li {
    padding: 0 8px 8px;
    width: 12.5%;
}
.block-grid.nine-up {
    margin: 0 -8px;
}
.block-grid.nine-up > li {
    padding: 0 8px 8px;
    width: 11.1111%;
}
.block-grid.ten-up {
    margin: 0 -8px;
}
.block-grid.ten-up > li {
    padding: 0 8px 8px;
    width: 10%;
}
.block-grid.eleven-up {
    margin: 0 -8px;
}
.block-grid.eleven-up > li {
    padding: 0 8px 8px;
    width: 9.09091%;
}
.block-grid.twelve-up {
    margin: 0 -8px;
}
.block-grid.twelve-up > li {
    padding: 0 8px 8px;
    width: 8.33333%;
}

@media only screen and (min-width: 1080px) {
    .block-grid.two-up > li:nth-child(2n+1),
    .block-grid.three-up > li:nth-child(3n+1),
    .block-grid.four-up > li:nth-child(4n+1),
    .block-grid.five-up > li:nth-child(5n+1),
    .block-grid.six-up > li:nth-child(6n+1),
    .block-grid.seven-up > li:nth-child(7n+1),
    .block-grid.eight-up > li:nth-child(8n+1),
    .block-grid.nine-up > li:nth-child(9n+1),
    .block-grid.ten-up > li:nth-child(10n+1),
    .block-grid.eleven-up > li:nth-child(11n+1),
    .block-grid.twelve-up > li:nth-child(12n+1) {
        clear: both;
    }
}

@media only screen and (max-width: 1080px) and (min-width: 767px) {
    .block-grid > li {
        clear: none;
    }
    .block-grid.tab-one-up > li {
        width: 100%;
    }
    .block-grid.tab-two-up > li {
        width: 50%;
    }
    .block-grid.tab-three-up > li {
        width: 33.3333%;
    }
    .block-grid.tab-four-up > li {
        width: 25%;
    }
    .block-grid.tab-five-up > li {
        width: 20%;
    }
    .block-grid.tab-six-up > li {
        width: 16.6667%;
    }
    .block-grid.tab-seven-up > li {
        width: 14.2857%;
    }
    .block-grid.tab-eight-up > li {
        width: 12.5%;
    }
    .block-grid.tab-nine-up > li {
        width: 11.1111%;
    }
    .block-grid.tab-ten-up > li {
        width: 10%;
    }
    .block-grid.tab-eleven-up > li {
        width: 9.09091%;
    }
    .block-grid.tab-twelve-up > li {
        width: 8.33333%;
    }
    
    .block-grid.tab-two-up > li:nth-child(2n+1),
    .block-grid.tab-three-up > li:nth-child(3n+1),
    .block-grid.tab-four-up > li:nth-child(4n+1),
    .block-grid.tab-five-up > li:nth-child(5n+1),
    .block-grid.tab-six-up > li:nth-child(6n+1),
    .block-grid.tab-seven-up > li:nth-child(7n+1),
    .block-grid.tab-eight-up > li:nth-child(8n+1),
    .block-grid.tab-nine-up > li:nth-child(9n+1),
    .block-grid.tab-ten-up > li:nth-child(10n+1),
    .block-grid.tab-eleven-up > li:nth-child(11n+1),
    .block-grid.tab-twelve-up > li:nth-child(12n+1) {
        clear: both;
    }
}

@media only screen and (max-width: 767px) {
    .block-grid > li {
        clear: none;
    }
    .block-grid.mobile-one-up > li {
        width: 100%;
    }
    .block-grid.mobile-two-up > li {
        width: 50%;
    }
    .block-grid.mobile-three-up > li {
        width: 33.3333%;
    }
    .block-grid.mobile-four-up > li {
        width: 25%;
    }
    .block-grid.mobile-five-up > li {
        width: 20%;
    }
    .block-grid.mobile-six-up > li {
        width: 16.6667%;
    }
    .block-grid.mobile-seven-up > li {
        width: 14.2857%;
    }
    .block-grid.mobile-eight-up > li {
        width: 12.5%;
    }
    .block-grid.mobile-nine-up > li {
        width: 11.1111%;
    }
    .block-grid.mobile-ten-up > li {
        width: 10%;
    }
    .block-grid.mobile-eleven-up > li {
        width: 9.09091%;
    }
    .block-grid.mobile-twelve-up > li {
        width: 8.33333%;
    }
    
    .block-grid.mobile-two-up > li:nth-child(2n+1),
    .block-grid.mobile-three-up > li:nth-child(3n+1),
    .block-grid.mobile-four-up > li:nth-child(4n+1),
    .block-grid.mobile-five-up > li:nth-child(5n+1),
    .block-grid.mobile-six-up > li:nth-child(6n+1),
    .block-grid.mobile-seven-up > li:nth-child(7n+1),
    .block-grid.mobile-eight-up > li:nth-child(8n+1),
    .block-grid.mobile-nine-up > li:nth-child(9n+1),
    .block-grid.mobile-ten-up > li:nth-child(10n+1),
    .block-grid.mobile-eleven-up > li:nth-child(11n+1),
    .block-grid.mobile-twelve-up > li:nth-child(12n+1) {
        clear: both;
    }
}