/* -----------------------------------------
            Buttons
----------------------------------------- */

.btn {
    border-radius: 0px;
    background-color: #f2702c;
    color: white;
    border-color: #353535;
    
    &:hover,
    &:focus,
    &:active,
    &:target {
        background-color: #f27c2c!important;
        color: white!important;
        border-color: #353535!important;
    }
}

.phone_number_mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .phone_number_mobile {
        display: block;
        text-align: center;
        color: #f2702c;
        font-size: 26px;
    }
    
    .phone_border {
        width: 100%;
        margin: 0 auto;
        padding: 20px 20px;
        margin-top: 40px;
        border: 1px solid;
    }
}