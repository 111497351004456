/* -----------------------------------------
                Logo
----------------------------------------- */

#logo {
    margin: 15px 0 15px 0;
    
    a {
        
    }
    
    img {
        
    }
}

@media only screen and (max-width: 768px) {
    #logo {
        text-align: center;
    }
}