/* -----------------------------------------
            Top Menu
----------------------------------------- */

#top-menu {
    margin: 36px 10px;
    
    ul {
        margin: 0;
        padding: 0;
    }
    
    li {
        margin: 0;
        padding: 0 10px;
        font-size: 16px;
        text-transform: uppercase;
    }
    
    a {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 300;
        color: #1f1f1f;
        
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: #f2702c;
            text-decoration: underline;
        }
    }
    .phone_number a {
        color: #f2702c;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    .write_us {
        background: #f38132; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(#f59239, #f2702c); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#f59239, #f2702c); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#f59239, #f2702c); /* For Firefox 3.6 to 15 */
        background: linear-gradient(#f59239, #f2702c); /* Standard syntax */
        padding: 10px 20px;
        a {
            margin-left: 0px;
            color: white;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #top-menu {
        margin-top: 90px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 540px) {
    #top-menu {
        margin: 0;
    }
    #top-menu li {
        width: 49%;
        height: 40px;
        a {
            font-size: 14px;
            display: block;
            text-align: center;
            vertical-align: middle;
        }
    }
}


@media only screen and (max-width: 342px) {
    #top-menu li {
        height: 20px;
        padding: 10px 0;
    }
    #top-menu .write_us {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    #top-menu {
        display: none;
    }
}